html {
  scroll-behavior: smooth;
}

/* body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
} */

body {
  margin: 0;
  background-color: rgb(0, 0, 0);
  color: rgb(224, 224, 224);
}

div.center-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 17%;
}