@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap);
html {
  scroll-behavior: smooth;
}

/* body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
} */

body {
  margin: 0;
  background-color: rgb(0, 0, 0);
  color: rgb(224, 224, 224);
}

div.center-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 17%;
}
/* .typed-subtext {
    font-size: 1.1rem;
    font-variant: small-caps;
    letter-spacing: 3px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    font-family: "Quicksand", sans-serif;
} */

@media only screen and (min-width: 901px) {
    .typed-subtext {
        font-size: 1.5rem;
    }
}

.typed-subtext {
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
    font-weight: 300;
}
.landing-container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
      to right,
      rgba(49, 49, 49, 0.2),
      rgba(0, 0, 0, 0.75)
    ),
    url(/static/media/landingshot.b07481e1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 40%;
}

.landing-content {
  margin: 15rem auto 0 auto;
  color: black;
  padding: 0 10%;
}

.greetings {
  position: relative;
  text-align: center;
  margin: 35vh auto 0 auto;
  font-family: "Open Sans", sans-serif;
}

#hi {
  margin: 0;
  -webkit-animation: fadeInAnimationFirst 1.2s ease-out;
          animation: fadeInAnimationFirst 1.2s ease-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  font-size: 1.5rem;
}

#im {
  -webkit-animation: fadeInAnimationSecond 2s ease-out;
          animation: fadeInAnimationSecond 2s ease-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

#name {
  margin: 0 0 1rem 0;
  -webkit-animation: fadeInAnimationThird 3s ease-out;
          animation: fadeInAnimationThird 3s ease-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-size: 2.5rem;
}

@-webkit-keyframes fadeInAnimationFirst {
  0% {
    transform: translate(0, 30px);
    color: rgba(255, 255, 255, 0);
  }

  to {
    transform: translate(0, 0);
    color: rgb(255, 255, 255);
  }
}

@keyframes fadeInAnimationFirst {
  0% {
    transform: translate(0, 30px);
    color: rgba(255, 255, 255, 0);
  }

  to {
    transform: translate(0, 0);
    color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes fadeInAnimationSecond {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    color: rgba(255, 255, 255, 0);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}

@keyframes fadeInAnimationSecond {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    color: rgba(255, 255, 255, 0);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}

@-webkit-keyframes fadeInAnimationThird {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    transform: translate(-25px, 0);
    color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translate(0, 0);
    color: rgba(255, 255, 255, 1);
  }
}

@keyframes fadeInAnimationThird {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    transform: translate(-25px, 0);
    color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translate(0, 0);
    color: rgba(255, 255, 255, 1);
  }
}

.arrow-icon {
  font-size: 3rem;
  -webkit-tap-highlight-color: transparent;
  -webkit-animation: arrowAnimation, secondaryArrowAnim;
          animation: arrowAnimation, secondaryArrowAnim;
  -webkit-animation-duration: 3s, 3s;
          animation-duration: 3s, 3s;
  -webkit-animation-delay: 5s, 3s;
          animation-delay: 5s, 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  position: absolute;
  cursor: pointer;
  bottom: 5rem;
  left: 48.5%;
  visibility: hidden;
  color: rgba(5, 92, 9, 0);
}

@-webkit-keyframes secondaryArrowAnim {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: none;
  }
}

@keyframes secondaryArrowAnim {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: none;
  }
}

@-webkit-keyframes arrowAnimation {
  0% {
    color: rgba(5, 92, 9, 0);
  }
  100% {
    visibility: visible;
    color: rgb(228, 228, 228);
  }
}

@keyframes arrowAnimation {
  0% {
    color: rgba(5, 92, 9, 0);
  }
  100% {
    visibility: visible;
    color: rgb(228, 228, 228);
  }
}

@media only screen and (min-width: 901px) {
  #hi {
    font-size: 2rem;
  }

  #name {
    font-size: 4rem;
  }
}

.body-container {
  display: flex;
  background-image: url(/static/media/abstract-background.62fd37ef.png);
}

.body-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5rem auto;
  background: white;
  box-sizing: border-box;
  max-width: 95vw;
}

.section-container {
  padding: 5rem 0;
}

h1.section-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: black;
  max-width: 90%;
  margin: 0 auto 0rem auto;
  text-align: center;
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

h1.section-header:before,
h1.section-header:after {
  content: "";
  width: 100px;
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
}

h1.section-header:before {
  background: black;
  right: 0.5em;
  margin-left: -50%;
}

h1.section-header:after {
  background: black;
  left: 0.5em;
  margin-right: -50%;
}

.section-content {
  font-size: 1.2rem;
  padding: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.section-content li,
ul {
  list-style-type: none;
  padding: 0;
}

#projects-section {
  color: rgb(107, 107, 107);
  font-family: "Montserrat", sans-serif;
}

#about-section {
  background: rgb(223, 223, 223);
  font-family: "Montserrat", sans-serif;
}

#about-section p {
  margin-bottom: 2rem;
}

#contact-section {
}

.contact-content p {
  text-align: center;
}

.contact-content a {
  text-decoration: none;
  font-size: 1.6rem;
  color: rgb(68, 127, 204);
  -webkit-tap-highlight-color: transparent;
}

.contact-content a:hover {
  color: rgb(93, 161, 250);
}

.contact-content a:active {
  color: rgb(42, 90, 153);
}

.contact-nav {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.linkedin-icon {
  font-size: 3rem;
}

.smile-icon {
  font-size: 2rem;
  color: rgb(77, 201, 184);
  margin: 0.8rem 0 0 0.5rem;
}

.smile-block {
  display: flex;
  flex: column 1;
}

@media only screen and (min-width: 800px) {
  .section-content {
    padding: 0 4rem;
  }

  h1.section-header {
    font-size: 2rem;
    margin: 0 auto 2rem auto;
  }

  #contact-header {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1285px) {
  .section-content {
    padding: 0 7.5rem;
  }
}

@media only screen and (min-width: 1680px) {
  .section-content {
    font-size: 1.4rem;
    padding: 0 10rem;
    max-width: 1200px;
  }

  h1.section-header {
    font-size: 2.3rem;
  }
}

.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Left side of the component */
.content-cover {
  flex: 0.4 1;
  margin-bottom: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 350px;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.content-cover h2 {
  color: rgb(56, 81, 224);
  margin: 0;
}

p.experience-subtitle {
  margin: 0;
  font-weight: 700;
  color: rgb(12, 182, 63);
}

.content-cover a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.zoom-in {
  width: 100%;
  height: 150px;
  border-radius: 30px;
  overflow: hidden;
}

.content-cover img {
  display: block;
  max-width: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 150px;
  transition: 0.3s ease-in-out;
  float: right;
  border-radius: 30px;
}

.content-cover img:hover {
  transform: scale(1.5);
}

.experience-content {
  flex: 1 1;
  margin-bottom: 5rem;
}

.experience-container p {
  margin: 0 0 1.5rem 0;
}

.experience-container p:last-child {
  margin: 0;
}

@media only screen and (min-width: 800px) {
  .experience-container {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 auto 4rem auto;
  }

  .content-cover {
    border-right: 2px solid rgb(161, 161, 161);
    padding: 0 2rem 0 0;
    margin: 0 2rem 0 0;
    text-align: right;
  }

  .content-cover img {
  }
}


@media only screen and (min-width: 1680px) {
  .content-cover {
    font-size: 1.2rem;
  }
}
.clipboard-container {
  display: flex;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 2rem;
}

.clipboard-container::before {
  content: "Email";
  position: absolute;
  color: rgb(139, 139, 139);
  font-size: 1rem;
  top: -1.3rem;
}

#to-copy {
  box-sizing: border-box;
  text-align: center;
}

.copy-btn {
  border: 2px solid black;
  color: black;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 0.5rem 1rem;
  cursor: pointer;
  -webkit-animation: buttonAnimation 3s;
          animation: buttonAnimation 3s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.copy-btn:hover {
}

.copy-popup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgb(167, 233, 187);
  z-index: -1;
  -webkit-animation: popupReveal;
          animation: popupReveal;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes popupReveal {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes popupReveal {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.copy-popup {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  top: -70px;
  right: 0;
  bottom: 70px;
  width: 10px;
  height: 10px;
  z-index: 1;
  background: rgb(0, 129, 22);
  color: rgb(0, 129, 22);
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  -webkit-animation: popupCreateAnim, popupFadeAnim;
          animation: popupCreateAnim, popupFadeAnim;
  -webkit-animation-duration: 3s, 3s;
          animation-duration: 3s, 3s;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
}

.copy-popup p {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation-container {
  position: relative;
  margin-left: 1rem;
}

@-webkit-keyframes popupCreateAnim {
  0% {
    top: -15px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  10% {
    top: -60px;
  }
  25% {
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    top: -45px;
  }
  30% {
    top: -80px;
  }
  35% {
    left: 0;
    transform: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: -70px;
    left: 0;
  }
  40% {
    border-radius: 0%;
    width: 10px;
    height: 100%;
  }
  50% {
    width: 100%;
  }
  100% {
    height: 100%;
    transform: none;
    left: 0;
    width: 100%;
    right: 0;
  }
}

@keyframes popupCreateAnim {
  0% {
    top: -15px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  10% {
    top: -60px;
  }
  25% {
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    top: -45px;
  }
  30% {
    top: -80px;
  }
  35% {
    left: 0;
    transform: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: -70px;
    left: 0;
  }
  40% {
    border-radius: 0%;
    width: 10px;
    height: 100%;
  }
  50% {
    width: 100%;
  }
  100% {
    height: 100%;
    transform: none;
    left: 0;
    width: 100%;
    right: 0;
  }
}

@-webkit-keyframes popupFadeAnim {
  0% {
    visibility: visible;
    opacity: 1;
  }
  20% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes popupFadeAnim {
  0% {
    visibility: visible;
    opacity: 1;
  }
  20% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@-webkit-keyframes buttonAnimation {
  0% {
    transform: none;
  }
  25% {
    transform: rotate(5deg) translateY(-5px);
  }
  30% {
    transform: rotate(-5deg) translateY(-5px);

    background: white;
    color: black;
  }
  35% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(5deg) translateY(-5px);
  }
  50% {
    transform: rotate(-5deg);
    background: black;
    color: white;
  }
  55% {
    transform: rotate(5deg);
  }
  100% {
    transform: none;
    background: black;
    color: white;
  }
}

@keyframes buttonAnimation {
  0% {
    transform: none;
  }
  25% {
    transform: rotate(5deg) translateY(-5px);
  }
  30% {
    transform: rotate(-5deg) translateY(-5px);

    background: white;
    color: black;
  }
  35% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(5deg) translateY(-5px);
  }
  50% {
    transform: rotate(-5deg);
    background: black;
    color: white;
  }
  55% {
    transform: rotate(5deg);
  }
  100% {
    transform: none;
    background: black;
    color: white;
  }
}

.copy-icon {
  margin-left: 0.5rem;
}

.check-icon {
}

@media only screen and (min-width: 800px) {
  .copy-btn {
    padding: 0.5rem 1.5rem;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.header-container {
  display: flex;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1;
}

.header-item {
  padding: 1rem 1rem;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
  font-weight: 800;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  background: rgb(255, 255, 255);
  z-index: 1;
}

.header-item p {
  position: relative;
  margin: 0;
  color: black;
  z-index: 2;
}

.header-item::before {
  content: "";
  position: absolute;
  background: rgb(0, 0, 0);
  top: 0%;
  bottom: -10%;
  transform: skewX(25deg) translate(0, -100%);
  width: 70%;
  transition-duration: 0.2s;
  left: 15%;
  z-index: 1;
}

.header-item:hover p {
  color: white;
}

.header-item:hover::before {
  transform: skewX(25deg);
}

.item-wrap {
  position: relative;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (min-width: 800px) {
  .header-item {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
}
