@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap");

.landing-container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
      to right,
      rgba(49, 49, 49, 0.2),
      rgba(0, 0, 0, 0.75)
    ),
    url("../images/landingshot.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 40%;
}

.landing-content {
  margin: 15rem auto 0 auto;
  color: black;
  padding: 0 10%;
}

.greetings {
  position: relative;
  text-align: center;
  margin: 35vh auto 0 auto;
  font-family: "Open Sans", sans-serif;
}

#hi {
  margin: 0;
  animation: fadeInAnimationFirst 1.2s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-size: 1.5rem;
}

#im {
  animation: fadeInAnimationSecond 2s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#name {
  margin: 0 0 1rem 0;
  animation: fadeInAnimationThird 3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-variant: small-caps;
  font-size: 2.5rem;
}

@keyframes fadeInAnimationFirst {
  0% {
    transform: translate(0, 30px);
    color: rgba(255, 255, 255, 0);
  }

  to {
    transform: translate(0, 0);
    color: rgb(255, 255, 255);
  }
}

@keyframes fadeInAnimationSecond {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    color: rgba(255, 255, 255, 0);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}

@keyframes fadeInAnimationThird {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  50% {
    transform: translate(-25px, 0);
    color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translate(0, 0);
    color: rgba(255, 255, 255, 1);
  }
}

.arrow-icon {
  font-size: 3rem;
  -webkit-tap-highlight-color: transparent;
  animation: arrowAnimation, secondaryArrowAnim;
  animation-duration: 3s, 3s;
  animation-delay: 5s, 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1, infinite;
  position: absolute;
  cursor: pointer;
  bottom: 5rem;
  left: 48.5%;
  visibility: hidden;
  color: rgba(5, 92, 9, 0);
}

@keyframes secondaryArrowAnim {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: none;
  }
}

@keyframes arrowAnimation {
  0% {
    color: rgba(5, 92, 9, 0);
  }
  100% {
    visibility: visible;
    color: rgb(228, 228, 228);
  }
}

@media only screen and (min-width: 901px) {
  #hi {
    font-size: 2rem;
  }

  #name {
    font-size: 4rem;
  }
}
