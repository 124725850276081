.body-container {
  display: flex;
  background-image: url("../images/abstract-background.png");
}

.body-content {
  width: fit-content;
  margin: 5rem auto;
  background: white;
  box-sizing: border-box;
  max-width: 95vw;
}

.section-container {
  padding: 5rem 0;
}

h1.section-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: black;
  max-width: 90%;
  margin: 0 auto 0rem auto;
  text-align: center;
  overflow: hidden;
  width: fit-content;
}

h1.section-header:before,
h1.section-header:after {
  content: "";
  width: 100px;
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
}

h1.section-header:before {
  background: black;
  right: 0.5em;
  margin-left: -50%;
}

h1.section-header:after {
  background: black;
  left: 0.5em;
  margin-right: -50%;
}

.section-content {
  font-size: 1.2rem;
  padding: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
  width: fit-content;
}

.section-content li,
ul {
  list-style-type: none;
  padding: 0;
}

#projects-section {
  color: rgb(107, 107, 107);
  font-family: "Montserrat", sans-serif;
}

#about-section {
  background: rgb(223, 223, 223);
  font-family: "Montserrat", sans-serif;
}

#about-section p {
  margin-bottom: 2rem;
}

#contact-section {
}

.contact-content p {
  text-align: center;
}

.contact-content a {
  text-decoration: none;
  font-size: 1.6rem;
  color: rgb(68, 127, 204);
  -webkit-tap-highlight-color: transparent;
}

.contact-content a:hover {
  color: rgb(93, 161, 250);
}

.contact-content a:active {
  color: rgb(42, 90, 153);
}

.contact-nav {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.linkedin-icon {
  font-size: 3rem;
}

.smile-icon {
  font-size: 2rem;
  color: rgb(77, 201, 184);
  margin: 0.8rem 0 0 0.5rem;
}

.smile-block {
  display: flex;
  flex: column;
}

@media only screen and (min-width: 800px) {
  .section-content {
    padding: 0 4rem;
  }

  h1.section-header {
    font-size: 2rem;
    margin: 0 auto 2rem auto;
  }

  #contact-header {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1285px) {
  .section-content {
    padding: 0 7.5rem;
  }
}

@media only screen and (min-width: 1680px) {
  .section-content {
    font-size: 1.4rem;
    padding: 0 10rem;
    max-width: 1200px;
  }

  h1.section-header {
    font-size: 2.3rem;
  }
}
