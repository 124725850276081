/* .typed-subtext {
    font-size: 1.1rem;
    font-variant: small-caps;
    letter-spacing: 3px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    font-family: "Quicksand", sans-serif;
} */

@media only screen and (min-width: 901px) {
    .typed-subtext {
        font-size: 1.5rem;
    }
}

.typed-subtext {
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
    font-weight: 300;
}