.clipboard-container {
  display: flex;
  position: relative;
  width: fit-content;
  margin-top: 2rem;
}

.clipboard-container::before {
  content: "Email";
  position: absolute;
  color: rgb(139, 139, 139);
  font-size: 1rem;
  top: -1.3rem;
}

#to-copy {
  box-sizing: border-box;
  text-align: center;
}

.copy-btn {
  border: 2px solid black;
  color: black;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 0.5rem 1rem;
  cursor: pointer;
  animation: buttonAnimation 3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.copy-btn:hover {
}

.copy-popup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgb(167, 233, 187);
  z-index: -1;
  animation: popupReveal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

@keyframes popupReveal {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.copy-popup {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  top: -70px;
  right: 0;
  bottom: 70px;
  width: 10px;
  height: 10px;
  z-index: 1;
  background: rgb(0, 129, 22);
  color: rgb(0, 129, 22);
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  animation: popupCreateAnim, popupFadeAnim;
  animation-duration: 3s, 3s;
  animation-fill-mode: forwards, forwards;
  animation-delay: 0s, 2s;
}

.copy-popup p {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation-container {
  position: relative;
  margin-left: 1rem;
}

@keyframes popupCreateAnim {
  0% {
    top: -15px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  10% {
    top: -60px;
  }
  25% {
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    top: -45px;
  }
  30% {
    top: -80px;
  }
  35% {
    left: 0;
    transform: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: -70px;
    left: 0;
  }
  40% {
    border-radius: 0%;
    width: 10px;
    height: 100%;
  }
  50% {
    width: 100%;
  }
  100% {
    height: 100%;
    transform: none;
    left: 0;
    width: 100%;
    right: 0;
  }
}

@keyframes popupFadeAnim {
  0% {
    visibility: visible;
    opacity: 1;
  }
  20% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes buttonAnimation {
  0% {
    transform: none;
  }
  25% {
    transform: rotate(5deg) translateY(-5px);
  }
  30% {
    transform: rotate(-5deg) translateY(-5px);

    background: white;
    color: black;
  }
  35% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(5deg) translateY(-5px);
  }
  50% {
    transform: rotate(-5deg);
    background: black;
    color: white;
  }
  55% {
    transform: rotate(5deg);
  }
  100% {
    transform: none;
    background: black;
    color: white;
  }
}

.copy-icon {
  margin-left: 0.5rem;
}

.check-icon {
}

@media only screen and (min-width: 800px) {
  .copy-btn {
    padding: 0.5rem 1.5rem;
  }
}
