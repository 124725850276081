.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Left side of the component */
.content-cover {
  flex: 0.4;
  margin-bottom: 1rem;
  height: fit-content;
  max-width: 350px;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.content-cover h2 {
  color: rgb(56, 81, 224);
  margin: 0;
}

p.experience-subtitle {
  margin: 0;
  font-weight: 700;
  color: rgb(12, 182, 63);
}

.content-cover a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.zoom-in {
  width: 100%;
  height: 150px;
  border-radius: 30px;
  overflow: hidden;
}

.content-cover img {
  display: block;
  max-width: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 150px;
  transition: 0.3s ease-in-out;
  float: right;
  border-radius: 30px;
}

.content-cover img:hover {
  transform: scale(1.5);
}

.experience-content {
  flex: 1;
  margin-bottom: 5rem;
}

.experience-container p {
  margin: 0 0 1.5rem 0;
}

.experience-container p:last-child {
  margin: 0;
}

@media only screen and (min-width: 800px) {
  .experience-container {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 auto 4rem auto;
  }

  .content-cover {
    border-right: 2px solid rgb(161, 161, 161);
    padding: 0 2rem 0 0;
    margin: 0 2rem 0 0;
    text-align: right;
  }

  .content-cover img {
  }
}


@media only screen and (min-width: 1680px) {
  .content-cover {
    font-size: 1.2rem;
  }
}