.header-container {
  display: flex;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1;
}

.header-item {
  padding: 1rem 1rem;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
  font-weight: 800;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  background: rgb(255, 255, 255);
  z-index: 1;
}

.header-item p {
  position: relative;
  margin: 0;
  color: black;
  z-index: 2;
}

.header-item::before {
  content: "";
  position: absolute;
  background: rgb(0, 0, 0);
  top: 0%;
  bottom: -10%;
  transform: skewX(25deg) translate(0, -100%);
  width: 70%;
  transition-duration: 0.2s;
  left: 15%;
  z-index: 1;
}

.header-item:hover p {
  color: white;
}

.header-item:hover::before {
  transform: skewX(25deg);
}

.item-wrap {
  position: relative;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (min-width: 800px) {
  .header-item {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
}